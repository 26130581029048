import React, { useState, useContext, useEffect } from "react";
import * as R from "ramda";
import { CardState, Checkbox, RadioButton } from "@foris/avocado-ui";
import { Types, BlockRanges, EditedSession, FormPageType } from "../../context/formData.reducer";
import { AppContext } from "../../context/EditSessionsContext";
import { useWarnings } from "../../hooks/useWarnings";
import { Day } from "@models/ISchema";
import cx from "classnames";
import FormItem from "../FormEdit/FormItem";
import css from "./days.module.scss";

interface DaysProps {
  infoMessage?: string;
}

const Days: React.FC<DaysProps> = ({ infoMessage }) => {
  const { state, dispatch } = useContext(AppContext);
  const [daysBySelection, setDaysBySelection] = useState({});
  const [selectedRadioDay, setSelectedRadioDay] = useState<Day>(null);
  const [warnings, setWarnings] = useWarnings(
    {
      onlyOneDay: {
        message: "Solo se puede seleccionar un día.",
        active: false,
        predicate: (form: FormPageType) => {
          const selection = form?.editedSessions?.blocks?.selected;
          const blocks = form?.editedSessions?.blocks;
          const selectedCreateSession = form?.selectedCreateSession;
          return !selectedCreateSession && selection === "hours" && (blocks?.days?.length ?? 0) > 1;
        },
      },
      daySelection: {
        message: "Debes seleccionar un día.",
        active: false,
        predicate: (form: FormPageType) => {
          const selection = form?.editedSessions?.blocks?.selected;
          const blocks = form?.editedSessions?.blocks;
          return selection === "hours" && !blocks?.day && !blocks?.days?.length;
        },
      },
    },
    form => !form?.savedSessionsToCreateIds && !form?.selectedSessions?.length,
  );
  const editedSessions = state?.form?.editedSessions;
  const dayLabelToDay = {
    Lun: Day.Monday,
    Mar: Day.Tuesday,
    Mie: Day.Wednesday,
    Jue: Day.Thursday,
    Vie: Day.Friday,
    Sab: Day.Saturday,
    Dom: Day.Sunday,
  };

  const someDeletedSessionIsSelected = R.pipe(
    R.map(R.propOr("-", "id")),
    R.any(R.flip(R.has)(state?.form?.sessionsToDelete)),
  )(state?.form?.selectedSessions);

  const onCheckboxDayClick = (day: Day) => () => {
    const blocks = editedSessions.blocks;
    const { days } = blocks;

    const newDays = daysBySelection[day]
      ? days?.filter(dayToFilter => dayToFilter !== day) ?? []
      : [...(days || []), day];
    const currentDay = R.head(newDays);

    daysBySelection[day] = !daysBySelection[day];
    setDaysBySelection(daysBySelection);

    dispatch({
      type: Types.BlocksEditedSessions,
      payload: {
        blocks: R.pipe(
          R.set(R.lensProp<BlockRanges>("days"), newDays),
          R.set(R.lensProp<BlockRanges>("day"), currentDay),
        )(blocks),
      },
    });
  };

  const onRadiobuttonDayClick = (day: Day) => () => {
    dispatch({
      type: Types.BlocksEditedSessions,
      payload: R.set(R.lensPath(["blocks", "day"]), day, editedSessions),
    });
  };

  useEffect(() => {
    if (!editedSessions?.blocks) return;
    setSelectedRadioDay(
      R.view(
        R.lensPath<EditedSession>(["blocks", "day"]),
        editedSessions,
      ),
    );
  }, [editedSessions?.blocks]);

  /**
   * Handle Day's form validations
   */
  useEffect(() => {
    setWarnings(state?.form);
  }, [state?.form?.assignmentEdited?.blocks, state?.form?.editedSessions?.blocks]);

  if (!(editedSessions?.blocks?.selected === "hours")) {
    return <></>;
  }

  return (
    <FormItem title="Días" type="day" infoMessage={infoMessage}>
      <section className={cx(css.days, "container-row")}>
        <p className={cx(css.days__label)}>Seleccionar Días</p>
        <div className={cx(css.days__options, "container-row")}>
          {state?.form?.selectedCreateSession
            ? R.toPairs(dayLabelToDay).map(([label, day]) => (
                <Checkbox
                  key={day}
                  disabled={someDeletedSessionIsSelected}
                  checked={daysBySelection[day]}
                  value={daysBySelection[day]}
                  labelRight={label}
                  className={cx(css.days__options_item, css.days__options_item_selectable)}
                  onChange={onCheckboxDayClick(day as Day)}
                />
              ))
            : R.toPairs(dayLabelToDay).map(([label, day]) => (
                <RadioButton
                  key={day}
                  disabled={someDeletedSessionIsSelected}
                  checked={day === selectedRadioDay}
                  value={daysBySelection[day]}
                  labelRight={label}
                  className={cx(css.days__options_item, css.days__options_item_selectable)}
                  onChange={onRadiobuttonDayClick(day as Day)}
                />
              ))}
        </div>
        {warnings.map(warning => (
          <div key={warning} className={css.warning}>
            <CardState typeCard="warning" key={warning} title="Error de validación">
              <p className={css.warning_text}>{warning}</p>
            </CardState>
          </div>
        ))}
      </section>
    </FormItem>
  );
};

export default Days;
