import uuid from "uuid-random";
import { reject } from "lodash";
import * as R from "ramda";
import { Session, Instructor, Classroom, Section } from "@models/ISchema";
import { Week, LinkPageType } from "./linkData.reducer";
import { initialState } from "./EditSessionsContext";
import { intervalsFormat } from "../utils/sessionFormat";
import {
  validateSession,
  handleSavedSessions,
  getNewEditedSessions,
  newSessionsToCreateByFormState,
  savableSessionsToCreate,
  sessionsToCreateBySelectedDays,
  handleToggleAllSessionsSelection,
  getNewSelectedSessionsBySection,
  forkSelectedSessions as forkSelectedSessionsFromContext,
} from "../utils/context";
import { compareSessions } from "../utils/compareSelected";
import {
  FormPageType,
  EditedSession,
  Assignment,
  SelectedSessionsBySectionPayload,
} from "./formData.reducer";

export const addSessionsToCreate = (state: FormPageType) => {
  const newSessionsToCreate = newSessionsToCreateByFormState(state);
  return {
    ...state,
    sessionsToCreate: R.concat(state.sessionsToCreate, newSessionsToCreate),
    selectedSessions: newSessionsToCreate,
    editedSessions: R.pipe(
      R.set(
        R.lensProp<EditedSession>("instructors"),
        R.view(R.lensPath([0, "instructors"]), newSessionsToCreate),
      ),
      R.set(
        R.lensProp<EditedSession>("blocks"),
        R.view(R.lensPath([0, "blocks"]), newSessionsToCreate),
      ),
      R.set(
        R.lensProp<EditedSession>("classrooms"),
        R.view(R.lensPath([0, "classrooms"]), newSessionsToCreate),
      ),
      R.set(
        R.lensProp<EditedSession>("intervals"),
        R.view(R.lensPath([0, "intervals"]), newSessionsToCreate),
      ),
    )({} as EditedSession),
  };
};

export const addSessionsToCreateBySelectedDays = (link: LinkPageType, state: FormPageType) => {
  const sessionsToCreate = sessionsToCreateBySelectedDays(state);
  const { editedSessions, assignmentSame } = getNewEditedSessions(
    sessionsToCreate,
    sessionsToCreate,
    link,
  );
  return {
    ...state,
    sessionsToCreate: R.concat(state?.sessionsToCreate ?? [], sessionsToCreate),
    selectedCreateSession: false,
    selectedSessions: sessionsToCreate,
    editedSessions,
    assignmentSame,
    errors: [],
  };
};

export const addSessionsToCreateWithNoDays = (state: FormPageType) => {
  const newSession: EditedSession = {
    id: uuid(),
    blocks: state?.editedSessions?.blocks,
    instructors: state?.editedSessions?.instructors as Instructor[],
    classrooms: state?.editedSessions?.classrooms as Classroom[],
    intervals: state?.editedSessions?.intervals as Week[],
    session: null as Session,
    isNew: false,
    isCloned: true,
    section: state?.selectedSection,
  };
  return {
    ...state,
    sessionsToCreate: R.append(newSession, state?.sessionsToCreate ?? []),
    selectedCreateSession: false,
    selectedSessions: [newSession],
    errors: [],
  };
};

export const assignmentEdited = (payload: Assignment, state: FormPageType) => {
  return {
    ...state,
    assignmentEdited: {
      ...state?.assignmentEdited,
      ...payload,
    },
  };
};

export const blocksEditedSessions = ({ blocks }: EditedSession, state: FormPageType) => {
  return R.set(R.lensPath(["editedSessions", "blocks"]), blocks, state);
};

export const classroomEditedSessions = ({ classrooms }: EditedSession, state: FormPageType) => {
  return R.set(R.lensPath(["editedSessions", "classrooms"]), classrooms, state);
};

export const cleanForm = (state: FormPageType) => {
  return {
    ...state,
    ...initialState.form,
  };
};

export const cleanFormCreateSession = (state: FormPageType) => {
  return {
    ...state,
    selectedCreateSession: false,
    selectedSessions: [],
    cloneSelected: [],
    editedSessions: null,
  };
};

export const cleanUnsavableSessionsToCreate = (state: FormPageType) => {
  const sessionsToCreate = savableSessionsToCreate(state.sessionsToCreate);
  return {
    ...state,
    sessionsToCreate,
  };
};

export const forkSelectedSessions = (link: LinkPageType, state: FormPageType) => {
  const { newSessions, savedSessions } = forkSelectedSessionsFromContext(state);
  const sessionsToCreate = [
    ...(state?.sessionsToCreate ?? []),
    ...(newSessions ?? []),
  ] as EditedSession[];

  return {
    ...state,
    savedSessions,
    sessionsToCreate,
    selectedSessions: newSessions,
    ...compareSessions(newSessions, link?.weeks ?? []),
  };
};

export const formCancel = (
  payload: (Session | EditedSession)[],
  link: LinkPageType,
  state: FormPageType,
) => {
  const cancel = getNewEditedSessions(state?.savedSessions, payload, link);
  return {
    ...state,
    ...cancel,
    assignmentEdited: cancel.assignmentSame,
    errors: [],
  };
};

/**
 * @param ErrorForm[]
 * @param state FormPageType
 *
 * @return state FormPageType
 */
export const formError = R.set(R.lensProp<FormPageType>("errors"));

/**
 * @param EditedSession
 * @param state FormPageType
 *
 * @return state FormPageType
 */
export const instructorEditedSessions = ({ instructors }: EditedSession, state: FormPageType) => {
  return R.set(R.lensPath(["editedSessions", "instructors"]), instructors, state);
};

/**
 * @param EditedSession
 * @param state FormPageType
 *
 * @return state FormPageType
 */
export const intervalsEditedSessions = ({ intervals }: EditedSession, state: FormPageType) => {
  return R.set(R.lensPath(["editedSessions", "intervals"]), intervals, state);
};

/**
 * @param state FormPageType
 *
 * @return state FormPageType
 */
export const notSelectedCreateSession = R.set(
  R.lensProp<FormPageType>("selectedCreateSession"),
  false,
);

export const removeSelectedSessions = (
  selectedSessions: (Session | EditedSession)[],
  state: FormPageType,
) => {
  const selectedSessionsById = R.reduce(
    (table, session) => R.assoc(session.id, session, table),
    {},
    selectedSessions ?? [],
  );
  const [filteredSavedSessions, filteredSessionsToCreate] = R.ap(
    [R.reject(R.pipe(R.propOr("-", "id"), R.flip(R.has)(selectedSessionsById)))],
    [state?.savedSessions ?? [], state?.sessionsToCreate ?? []],
  );
  const newSessionsToDelete = R.pipe(
    R.filter(({ id }) => R.not(id?.includes("-"))),
    R.reduce((acc, session) => R.assoc(session?.id, session, acc), {}),
  )(selectedSessions);

  return {
    ...state,
    editedSessions: {
      blocks: { day: null, selected: null },
      classrooms: null,
      instructors: null,
      intervals: null,
    },
    savedSessions: filteredSavedSessions,
    selectedSessions: [],
    selectedSection: null,
    cloneSelected: [],
    sessionsToCreate: filteredSessionsToCreate,
    sessionsToDelete: R.mergeLeft(newSessionsToDelete, state?.sessionsToDelete ?? {}),
  };
};

export const savedNewSession = (payload: EditedSession, state: FormPageType) => {
  let savedNew = state.savedSessions ? [...state.savedSessions] : [];
  const sessionNew = { ...payload };
  if (sessionNew.id) {
    const findSession = savedNew.find(value => value.id === sessionNew.id);
    if (findSession) {
      // update new session
      const rejectSession = reject(savedNew, session => session.id === findSession.id);
      savedNew = rejectSession;
      savedNew.push(sessionNew);
    } else {
      // create new session without id
      sessionNew.isNew = true;
      sessionNew.section = state?.selectedSection;
      savedNew.push(sessionNew);
    }
  } else {
    // create new session with id
    sessionNew.id = uuid();
    sessionNew.isNew = true;
    sessionNew.section = state?.selectedSection;
    savedNew.push(sessionNew);
  }
  return {
    ...state,
    savedSessions: savedNew,
    selectedCreateSession: true,
    selectedSessions: [],
    cloneSelected: [],
    editedSessions: payload,
  };
};

export const savedSessions = (link: LinkPageType, state: FormPageType) => {
  const {
    saveValidation,
    naturalSessions,
    sessionsToCreate,
    savedSessionsToCreateIds,
  } = handleSavedSessions(state, link);

  return {
    ...state,
    savedSessions: naturalSessions,
    sessionsToCreate,
    savedSessionsToCreateIds: {
      ...state.savedSessionsToCreateIds,
      ...savedSessionsToCreateIds,
    },
    ...saveValidation,
    errors: [],
  };
};

export const selectedCreateSession = (
  payload: Section,
  link: LinkPageType,
  state: FormPageType,
) => {
  // check by default the weeks that are related to the link's course-component
  const component = payload?.component?.code;
  const intervals = R.map(week => {
    return R.set(
      R.lensProp("checked"),
      R.has(
        week?.id,
        R.has(component, link?.assignedWeeksByComponent)
          ? link?.assignedWeeksByComponent[component]
          : {},
      ),
      week,
    );
  }, intervalsFormat(null, link?.weeks ?? []));

  return {
    ...state,
    selectedSessions: [],
    cloneSelected: [],
    editedSessions: {
      blocks: {
        days: [],
        day: null,
        startTime: null,
        endTime: null,
        blocks: null,
        selected: null,
      },
      instructors: [],
      classrooms: [],
      intervals,
    },
    selectedCreateSession: true,
    selectedSection: payload,
  };
};

export const selectedSaveSession = (payload: EditedSession, state: FormPageType) => {
  return {
    ...state,
    editedSessions: payload,
    selectedSessions: [],
    errors: [],
    selectedSection: payload.section,
  };
};

export const selectedSessions = (
  payload: Session | EditedSession,
  link: LinkPageType,
  state: FormPageType,
) => {
  const { assignmentSame, cloneSelected, editedSessions } = validateSession(payload, state, link);

  return {
    ...state,
    selectedSessions: cloneSelected,
    assignmentEdited: assignmentSame,
    editedSessions,
    errors: [],
  };
};

export const selectedSessionsBySection = (
  payload: SelectedSessionsBySectionPayload,
  link: LinkPageType,
  state: FormPageType,
) => {
  const newSelectedSessionsBySection = getNewSelectedSessionsBySection(
    payload.checked,
    payload.sessionsOfSection,
    state,
    payload.sectionId,
  );
  const validationBySection = getNewEditedSessions(
    state?.savedSessions ?? [],
    newSelectedSessionsBySection,
    link,
  );
  return {
    ...state,
    ...validationBySection,
    selectedSessions: newSelectedSessionsBySection,
    assignmentEdited: validationBySection.assignmentSame,
    errors: [],
    selectedCreateSession: false,
  };
};

export const setRemovedWeekIdsBySessionId = (
  payload: { [key: string]: string[] },
  state: FormPageType,
) => {
  return {
    ...state,
    removedWeekIdsBySessionId: R.pipe(
      R.mergeRight(state?.removedWeekIdsBySessionId),
      R.toPairs,
      R.filter(([, weekIds]) => R.not(R.isEmpty(weekIds))),
      R.fromPairs,
    )(payload),
  };
};

/**
 * @param boolean
 * @param state FormPageType
 *
 * @return state FormPageType
 */
export const setSessionFromLocationSelected = R.set(
  R.lensProp<FormPageType>("sessionFromLocationSelected"),
);

/**
 * @param string
 * @param state FormPageType
 *
 * @return state FormPageType
 */
export const setSessionRecommendationIdSelected = R.set(
  R.lensProp<FormPageType>("sessionRecommendationIdSelected"),
);

export const toggleAllSessionsSelection = (
  { currentCheckbox, link: linkContext }: { currentCheckbox: boolean; link: LinkPageType },
  state: FormPageType,
) => {
  const { newAssignmentSame, newSelectedSessions } = handleToggleAllSessionsSelection(
    state,
    linkContext,
    !currentCheckbox,
  );
  return {
    ...state,
    selectedSessions: newSelectedSessions,
    assignmentSame: newAssignmentSame,
    errors: [],
    selectedCreateSession: false,
  };
};

export const undoEditionOverSelectedSessions = (payload: EditedSession[], state: FormPageType) => {
  const selected = payload;
  const selectedById = R.reduce((acc, session) => R.assoc(session?.id, true, acc), {}, selected);
  const nonSelected = R.reject(R.pipe(R.propOr("-", "id"), R.flip(R.has)(selectedById)));

  return {
    ...state,
    editedSessions: selected?.length ? state?.editedSessions : null,
    savedSessions: nonSelected(state?.savedSessions),
    selectedSessions: [],
    sessionsToCreate: nonSelected(state?.sessionsToCreate),
    cloneSelected: nonSelected(state?.cloneSelected),
    sessionsToDelete: R.omit(R.keys(selectedById), state?.sessionsToDelete),
    removedWeekIdsBySessionId: R.pipe(
      R.toPairs,
      R.reject(R.pipe(R.head, R.has(R.__, selectedById))),
      R.fromPairs,
    )(state?.removedWeekIdsBySessionId),
  };
};
