import React, { useContext, useState, useEffect } from "react";
import * as R from "ramda";
import Days from "../../components/Days/Days";
import cx from "classnames";
import { Button, Snackbar } from "@foris/avocado-ui";
import { AppContext } from "../../context/EditSessionsContext";
import { Types, FormActions } from "../../context/formData.reducer";
import Classroom from "./Classroom/Classroom";
import Repeat from "../../components/Repeat/Repeat";
import BlockRange from "../../components/BlockRange/BlockRange";
import FormHeader from "../../components/FormEdit/FormHeader";
import Instructor from "./Instructor/Instructor";
import Modal from "@components/modal/ModalState/ModalState";
import { buildAssignmentSame } from "../../utils/compareSelected";
import css from "./formEdit.module.scss";

interface CreateSessionProps {
  warning: boolean;
  setWarning: (warning: boolean) => void;
}

const CreateSession: React.FC<CreateSessionProps> = props => {
  const { warning, setWarning } = props;
  const { state, dispatch } = useContext(AppContext);
  const [variables, setVariables] = useState<FormActions>(null);
  const [activeSanckbar, setActiveSanckbar] = useState(false);
  const editedSessions = state?.form?.editedSessions;
  let edited = false;
  if (state?.form?.savedSessions?.length > 0) {
    const findSaved = state?.form?.savedSessions
      ?.filter(value => value.isNew === true)
      .find(value => value?.id === editedSessions?.id);
    if (findSaved) {
      edited = R.pipe(
        buildAssignmentSame,
        R.values,
        R.all(R.equals(true)),
      )([findSaved, editedSessions]);
    }
  } else {
    edited =
      !!editedSessions?.intervals.filter(R.propOr(false, "checked"))?.length ||
      !!editedSessions?.blocks?.selected ||
      !!editedSessions?.classrooms?.length ||
      !!editedSessions?.instructors?.length;
  }

  const creationButtonMessage = (num: number) => {
    if (!num) return "Crear";
    return num === 1 ? "Crear sesión" : `Crear ${num} sesiones`;
  };

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const uncreatableSessions = (): boolean => {
    const selection = state?.form?.editedSessions?.blocks?.selected;
    const blocks = state?.form?.editedSessions?.blocks;

    const blocksNotConfigured =
      !selection ||
      (selection === "blocks" && !blocks?.blocks) ||
      (selection === "hours" && !blocks?.days?.length) ||
      (selection === "hours" && blocks?.endTime <= blocks?.startTime);
    const weeksNotDefined = R.pipe(
      R.filter(R.propOr(false, "checked")),
      R.reduce(R.add(1), 0),
      R.flip(R.gt)(0),
      R.not,
    )(state?.form?.editedSessions?.intervals ?? []);

    return R.or(blocksNotConfigured, weeksNotDefined);
  };

  const cancelEditForm = () => {
    if (edited) {
      setWarning(true);
      setVariables({ type: Types.CleanFormCreateSession });
    } else {
      dispatch({ type: Types.CleanFormCreateSession });
    }
  };

  useEffect(() => {
    if (activeSanckbar) {
      if (state?.form?.editedSessions?.blocks?.days?.length) {
        dispatch({ type: Types.AddSessionsToCreateBySelectedDays });
      } else {
        dispatch({ type: Types.AddSessionsToCreateWithNoDays });
      }
      setTimeout(() => setActiveSanckbar(false), 500);
    }
  }, [activeSanckbar]);

  return (
    <>
      <Snackbar
        type="confirm"
        setValueActive={(value: any) => setActiveSanckbar(value)}
        active={activeSanckbar}
        icon="circle-check"
        duration={3}
      >
        Tu nueva sesión ha sido guardada con éxito
      </Snackbar>
      {warning && (
        <Modal
          typeState="warning"
          title="¿Estás seguro?"
          show={warning}
          textButtonPrincipal="Confirmar"
          onClickPrincipal={() => {
            setWarning(false);
            if (variables) {
              dispatch(variables);
            } else {
              dispatch({ type: Types.NotSelectedCreateSession, payload: false });
              dispatch({ type: Types.CleanFormCreateSession });
            }
          }}
          textButtonSecondary="Cancelar"
          onClickSecondary={() => setWarning(false)}
        >
          No has terminado de crear la nueva sesión. Si cambias tu selección se perderán estos
          cambios.
        </Modal>
      )}
      <section className={cx(css.formEdit)}>
        <FormHeader />
        <section className={css.fields}>
          <section className={css.fields_content}>
            <BlockRange />
            <Days infoMessage="Al seleccionar varios días, puedes crear varias sesiones a la vez" />
            <Instructor />
            <Classroom />
            <Repeat originalWeeksBySessionId={{}} />
          </section>
        </section>
        <footer className={cx(css.footer, "container-row")}>
          <Button
            typeButton="transparent"
            className={cx(css.footer_btn)}
            onClick={() => cancelEditForm()}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              scrollToTop();
              setActiveSanckbar(true);
            }}
            disabled={uncreatableSessions()}
            className={cx(css.footer_btn)}
          >
            {creationButtonMessage(editedSessions?.blocks?.days?.length)}
          </Button>
        </footer>
      </section>
    </>
  );
};

export default CreateSession;
