import React, { useEffect, useState, useContext } from "react";
import * as R from "ramda";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { IParams } from "@models/IParams";
import { Instructor, CubeQuery } from "@models/ISchema";
import { AppContext } from "../../../context/EditSessionsContext";
import { Types } from "../../../context/switchPage.reducer";
import FormItem from "../../../components/FormEdit/FormItem";
import SimpleTableSearch from "../../../components/Instructor/SimpleTable";
import Autocomplete from "../../../components/Instructor/Autocomplete";
import { useDebounce } from "../../../hooks/useDebounce";
import { instructorSearch } from "../../../graphql/instructor.query";
import css from "./instructor.module.scss";

const InstructorSearch = () => {
  const { state, dispatch } = useContext(AppContext);
  const client = useApolloClient();
  const { scenario, origin }: IParams = useParams();
  const [valueSearch, setValueSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const debouncedSearch = useDebounce(valueSearch, 500);

  const someDeletedSessionIsSelected = R.pipe(
    R.map(R.propOr("-", "id")),
    R.any(R.flip(R.has)(state?.form?.sessionsToDelete)),
  )(state?.form?.selectedSessions);

  const serializeOptions = (isntructors: Instructor[]) => {
    const instructorOptions = [];

    if (isntructors.length === 0) return instructorOptions;

    isntructors.forEach(instructor => {
      instructorOptions.push({
        ...instructor,
        label: `${instructor.code} - ${instructor.name}`,
        value: instructor.id,
      });
    });
    return instructorOptions;
  };

  const queryOptions = (value: string) => {
    return {
      query: instructorSearch,
      variables: {
        originId: origin,
        scenarioId: scenario,
        filter: {
          page: 1,
          size: 20,
          searchTerm: value,
        },
      },
    };
  };

  const getDataOptions = async (value: string) => {
    try {
      setLoading(true);
      if (value !== "") {
        const query = queryOptions(value);
        const { data } = await client.query(query);
        const dataQuery: CubeQuery = data?.cube;
        const insOptions = serializeOptions(dataQuery.instructors.items);
        setOptions(insOptions);
        setLoading(false);
      }
    } catch (error) {
      setOptions([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (debouncedSearch) {
      setLoading(true);
      getDataOptions(debouncedSearch);
    } else {
      setOptions([]);
    }
  }, [debouncedSearch]);

  return (
    <FormItem title="Docente" type="instructors">
      <div className={css.content}>
        <div className={css.item}>
          <Autocomplete
            disabled={someDeletedSessionIsSelected}
            loading={loading}
            options={options}
            className={cx(css.item_select)}
            setValueSearch={value => setValueSearch(value)}
          />
          <button
            disabled={someDeletedSessionIsSelected}
            onClick={() => {
              dispatch({
                type: Types.AdvanceSearchInstructor,
                payload: true,
              });
            }}
            className={css.item_link}
          >
            Búsqueda Avanzada
          </button>
        </div>
        <SimpleTableSearch disabled={someDeletedSessionIsSelected} />
      </div>
    </FormItem>
  );
};

export default InstructorSearch;
